import {ApplicationResponse, TaggedDataState,} from "@common/domain/common.model";
import {TaggedAction} from "@common/domain/common.props";
import api from "@common/services/api.service";
import {createAsyncThunk, PayloadAction, SerializedError,} from "@reduxjs/toolkit";
import AppConstants from "@src/environment/app.constants";
import {
    FourMChangesRef,
    HourlyProductionHistoryRef,
    PmsStore,
    ProductionHistoryRef, StationOperatorEntryRef, StationOperatorRef,
} from "@src/views/pms/domain/pms.model";
import moment from "moment";
import {createSecuredSlice} from "@utils/auth.utils";

export const fetchProductionHistory = createAsyncThunk<
    TaggedDataState<ProductionHistoryRef[]>,
    TaggedAction
>("productionHistory/fetchProductionHistory", async (payload) => {
    const {assemblyId, entryStartDate, entryEndDate, modelId} = payload.parameters;
    const defaultUrl = `${AppConstants.api}/entry?startDate=${entryStartDate}&endDate=${entryEndDate}`;
    const url = assemblyId ? `${defaultUrl}&lineId=${assemblyId}` : `${defaultUrl}${modelId ? `&modelId=${modelId}` : ''}`;
    // const url = `${AppConstants.api}/entry?lineId=${assemblyId}&startDate=${entryStartDate}&endDate=${entryEndDate}`;
    console.log("url", url)

    const response = await api.get<ApplicationResponse<ProductionHistoryRef[]>>(
        url
    );

    const formattedData = response.data.data?.map((entry) => ({
        ...entry,
        date: moment(entry.date, "DD-MM-YYYY").format("DD MMMM YYYY"),
    }));

    return {
        tag: payload.tag,
        data: formattedData || [],
    };
});
export const fetchStationOperatorList = createAsyncThunk<StationOperatorRef[], string>("productionHistory/fetchStationOperatorList", async (id) => {
    const response = await api.get<ApplicationResponse<StationOperatorEntryRef>>(`${AppConstants.api}/entry/operator/${id}/mapping`);
    return (response?.data?.data?.stations || []) as StationOperatorRef[];
    // return [
    //     {
    //     id: "osmeime",
    //     station: "some station",
    //     operator: "some operator"
    // }, {
    //     id: "osmeime",
    //     station: "some station",
    //     operator: "some operator"
    // }, {
    //     id: "osmeime",
    //     station: "some station",
    //     operator: "some operator"
    // }, {
    //     id: "osmeime",
    //     station: "some station",
    //     operator: "some operator"
    // }]
})

export const fetchHourlyProductionHistory = createAsyncThunk<
    TaggedDataState<HourlyProductionHistoryRef[]>,
    TaggedAction
>("productionHistory/fetchHourlyProductionHistory", async (payload) => {
    console.log("********",payload.parameters)
    const {assemblyId, shiftStartTime, shiftEndTime, modelId, shiftId} = payload.parameters;
    // const defaultUrl = `${AppConstants.api}/entry?startDate=${entryStartDate}&endDate=${entryEndDate}`;
    const url = `${AppConstants.api}/entry/report/hourly?startTime=${shiftStartTime}&endTime=${shiftEndTime}&modelId=${modelId}&lineId=${assemblyId}&shiftId=${shiftId}`;
    // const url = `${AppConstants.api}/entry?lineId=${assemblyId}&startDate=${entryStartDate}&endDate=${entryEndDate}`;

    const response = await api.get<ApplicationResponse<HourlyProductionHistoryRef[]>>(
        url
    );

    const formattedData = response.data.data?.map((entry) => ({
        ...entry,
        date: moment(entry.date, "DD-MM-YYYY").format("DD MMMM YYYY"),
    }));

    return {
        tag: payload.tag,
        data: formattedData || [],
    };
});

export const fetchFourMHistory = createAsyncThunk<
    TaggedDataState<FourMChangesRef[]>,
    TaggedAction
>("fourMHistory/fetchFourMHistory", async (payload, thunkAPI) => {
    try {
        if (payload == null) throw new Error("Line ID is required");
        const {lineId, startDate, endDate} = payload.parameters
        const defaultUrl = `${AppConstants.api}/fourM/history?startDate=${startDate}&endDate=${endDate}`;
        const url = lineId ? `${defaultUrl}&lineId=${lineId}` : defaultUrl
        // const url = `${AppConstants.api}/fourM/history?lineId=${lineId}&startDate=${startDate}&endDate=${endDate}`;
        const response = await api.get<ApplicationResponse<FourMChangesRef[]>>(url);
        return {
            tag: payload.tag,
            data: response?.data?.data as FourMChangesRef[]
        };
        // return {
        //     tag: payload.tag,
        //     data: ([
        //         {
        //             id: "osmeime",
        //             station: "some station",
        //             station_id: "stationIdddddd",
        //             line_id: "LineIdddddd",
        //             change_type: "categoryy",
        //             remarks: "yoToo qwnxoiwnxowu xqw xqwuoxwo xqwu xqow uxquow qwou xqow xqwoi xwqio xqwio xqwoi xqwioxqwoi xqoiwx qwoix qwoi xqwoixwqoixqwoi ",
        //             raised_date: "12/10/1022", // date string
        //             review_date: "12/12/2021", // date string
        //         },
        //     ] || []) as FourMChangesRef[],
        // };
    } catch (err: any) {
        if (!err.response) {
            return thunkAPI.rejectWithValue(err.response.data);
        }

        return thunkAPI.rejectWithValue({
            error: {
                message: err.response.data.message,
                code: err.response.data.code,
            },
        });
    }
});

const pmsSlice = createSecuredSlice({
    name: "pms",
    initialState: {minimal: {}, fourMChanges: {}, hourlyProductionHistory: {}, stationOperatorList: {}} as PmsStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchProductionHistory.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {
                        arg: {tag},
                    } = action.meta;

                    state.minimal[tag] = {
                        status: "loading",
                        error: null,
                        data: [],
                    };
                }
            )
            .addCase(fetchProductionHistory.fulfilled, (state, action) => {
                const {tag, data} = action.payload;
                state.minimal[tag] = {
                    status: "idle",
                    error: null,
                    data,
                };
            })
            .addCase(
                fetchProductionHistory.rejected,
                (
                    state,
                    action: PayloadAction<
                        unknown,
                        string,
                        { arg: TaggedAction },
                        SerializedError
                    >
                ) => {
                    const {
                        arg: {tag},
                    } = action.meta;
                    state.minimal[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: [],
                    };
                }
            )
            .addCase(
                fetchHourlyProductionHistory.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {
                        arg: {tag},
                    } = action.meta;

                    state.hourlyProductionHistory[tag] = {
                        status: "loading",
                        error: null,
                        data: [],
                    };

                }
            )
            .addCase(fetchHourlyProductionHistory.fulfilled, (state, action) => {
                const {tag, data} = action.payload;
                state.hourlyProductionHistory[tag] = {
                    status: "idle",
                    error: null,
                    data,
                };
            })
            .addCase(
                fetchHourlyProductionHistory.rejected,
                (
                    state,
                    action: PayloadAction<
                        unknown,
                        string,
                        { arg: TaggedAction },
                        SerializedError
                    >
                ) => {
                    const {
                        arg: {tag},
                    } = action.meta;
                    state.hourlyProductionHistory[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: [],
                    };
                }
            )
            .addCase(
                fetchFourMHistory.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {
                        arg: {tag},
                    } = action.meta;

                    state.fourMChanges[tag] = {
                        status: "loading",
                        error: null,
                        data: [],
                    };
                }
            )
            .addCase(fetchFourMHistory.fulfilled, (state, action) => {
                const {tag, data} = action.payload;
                state.fourMChanges[tag] = {
                    status: "idle",
                    error: null,
                    data,
                };
            })
            .addCase(
                fetchFourMHistory.rejected,
                (
                    state,
                    action: PayloadAction<
                        unknown,
                        string,
                        { arg: TaggedAction },
                        SerializedError
                    >
                ) => {
                    const {
                        arg: {tag},
                    } = action.meta;
                    state.fourMChanges[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: [],
                    };
                }
            ).addCase(fetchStationOperatorList.pending, (state) => {
            state.stationOperatorList = {
                status: "loading",
                error: null,
                data: [] as StationOperatorRef[]
            }
        }).addCase(fetchStationOperatorList.fulfilled, (state, action) => {
            state.stationOperatorList = {
                status: "idle",
                error: null,
                data: action.payload
            }
        }).addCase(fetchStationOperatorList.rejected, (state, action) => {
            state.stationOperatorList = {
                status: "failed",
                error: action.error.message,
                data: []
            }
        });
    },
});

export default pmsSlice.reducer;
