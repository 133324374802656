import {StandardTimestamp} from "@common/domain/common.model";

export interface AssignedModelMinimalRef {
    id: string;
    modelName: string;
    cycleTime: number;
    lineId: string;
    lineName: string;
    modelId: string;
    changeOverSheet: string;
    createdBy: string;
    createdOn: StandardTimestamp
}

export interface ModelMinimalRef {
    id: string;
    title: string;
    cycleTime?: number;
    createdBy: string;
    createdOn: StandardTimestamp
    assignments: AssignedModelMinimalRef[]
}

export interface ModelSearchRef {
    id: string
    name: string
}

export interface AssignModelRef {
    modelId?: string;
    file?: File;
    lines: string[]
}
export interface UpdateChangeOverSheetRef {
    modelId?: string;
    file?: File;
    lineId?: string;
}

export function toModelMinimal(payload: AssignedModelMinimalRef[]): ModelMinimalRef[] {

    return payload.map(model => ({
        id: model.modelId,
        title: model.modelName,
        cycleTime: model.cycleTime,
        assignments: [model],
        createdBy: model.createdBy,
        createdOn: model.createdOn
    }))
}