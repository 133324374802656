import {ApplicationResponse} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import api from "@common/services/api.service"
import {createAsyncThunk, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import AppConstants from "@src/environment/app.constants"
import {NewSkillRef, ProcessRef, ProcessStore} from "@src/views/process/domain/process.model"
import {createSecuredSlice} from "@utils/auth.utils";

export const MockedData: any[] = [
    {
        id: 1,
        tag: 'Tag 1',
        title: 'Mock Data',
        description: `This is a mock data for testing purpose`,

    }
]

export const fetchProcess =
    createAsyncThunk<ProcessRef[], TaggedAction>("process/fetchProcess",
        async () => {
            const response = await api.get<ApplicationResponse<ProcessRef[]>>(`${AppConstants.api}/skill/list`)
            return response.data.data || []
            // return MockedData
        })

export const uploadProcess = createAsyncThunk<void, {

    payload: NewSkillRef
}>("process/upload",
    async ({payload}, thunkAPI) => {


        try {

            const url = `${AppConstants.api}/skill`

            const apiPayload = {
                tag: payload.tag,
                title: payload.title,
                description: payload.description,
            }

            const response = await api.post<ApplicationResponse<void>>(url, apiPayload)
            return response.data.data
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code
                }
            })
        }
    })


const processSlice = createSecuredSlice({
    name: "process",
    initialState: {minimal: {}} as ProcessStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProcess.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    // const { arg: { tag } } = action.meta

                    state.minimal = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchProcess.fulfilled, (state, action) => {
                console.log("action is fulfilled", action, "action payload", action.payload)
                const
                    data
                        = action.payload
                state.minimal = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchProcess.rejected, (state,
                                             action: PayloadAction<unknown, string,
                                                 { arg: TaggedAction }, SerializedError>) => {
                // const { arg: { tag } } = action.meta
                state.minimal = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
    }
})

export default processSlice.reducer