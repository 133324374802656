import {combineReducers} from "@reduxjs/toolkit"

import dialogReducer from "@slices/dialog.slice"
import assemblyLinesReducer from "@slices/lines.slice"
import loadingReducer from "@slices/loader.slice"
import onePointLessonReducer from "@slices/opl.slice"
import stationReducer from "@slices/station.slice"
import videosReducer from "@slices/video.slice"
import workInstructionsReducer from "@slices/workInstruction.slice"
import machineManualsReducer from "@slices/machine-manual.slice"
import dosAndDontsReducer from "@slices/dos-and-donts.slice"
import startupSheetReducer from "@slices/startupsheet.slice"
import modelsReducer from "@slices/models.slice"
import configReducer from "@slices/config.slice"
import emergencyReducer from "@slices/emergency.slice"
import departmentReducer from "@slices/department.slice"
import skillsReducer from "@slices/skill.slice"
import userReducer from "@slices/user.slice"
import checksheetReducer from "@slices/checksheet.slice"
import drawingReducer from "@slices/drawing.slice"
import authReducer from "@slices/auth.slice"
import escalationReducer from "@slices/escalation.slice"
import andonReducer from "@slices/andon.slice"
import dashboardReducer from "@slices/dashboard.slice"
import categoryReducer from "@slices/category.slice"
import pmsReducer from "@slices/pms.slice"
import plannerReducer from "@slices/planner.slice"
import shiftReducer from "@slices/shift.slice"
import holidaysReducer from "@slices/holidays.slice"
import reportReducer from "@slices/dashboard-reports.slice"
import incidentInfoReducer from '@slices/incident.slice'
import brokerReducer from "@slices/broker.slice"
import processReducer from "@slices/process.slice"
import viewerReducer from "@slices/viewer.slice"

const rootReducer = combineReducers({
    config: configReducer,
    broker: brokerReducer,
    dialogs: dialogReducer,
    reports: reportReducer,
    onePointLessons: onePointLessonReducer,
    assemblyLines: assemblyLinesReducer,
    stations: stationReducer,
    workInstructions: workInstructionsReducer,
    videos: videosReducer,
    loading: loadingReducer,
    manuals: machineManualsReducer,
    dosAndDonts: dosAndDontsReducer,
    startupSheets: startupSheetReducer,
    models: modelsReducer,
    emergency: emergencyReducer,
    departments: departmentReducer,
    skills: skillsReducer,
    users: userReducer,
    checksheets: checksheetReducer,
    drawings: drawingReducer,
    auth: authReducer,
    andon: andonReducer,
    dashboard: dashboardReducer,
    escalation: escalationReducer,
    category: categoryReducer,
    pms: pmsReducer,
    shiftPlanner: plannerReducer,
    shift: shiftReducer,
    holidays: holidaysReducer,
    incidentInfo: incidentInfoReducer,
    process: processReducer,
    viewer: viewerReducer
})

export default rootReducer