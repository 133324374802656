import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import api from "@common/services/api.service"
import {createAsyncThunk, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import convertToForm from "@utils/payload-to-form-converter"
import AppConstants from "@src/environment/app.constants"
import {NewUserModelRef, UserModelMinimalRef, UserSearchRef, UserStore} from "@src/views/users/domain/user.model"
import {createSecuredSlice} from "@utils/auth.utils";


export const fetchUserList =
    createAsyncThunk<TaggedDataState<UserModelMinimalRef[]>, TaggedAction>("users/fetchUsers",
        async (payload) => {
            const {config} = payload != null && payload.parameters != null ? payload.parameters : undefined

            let url = `${AppConstants.api}/users`

            if (config) url = `${url}?config=${config}`
            const response = await api.get<ApplicationResponse<UserModelMinimalRef[]>>(url)
            console.log("response", response)
            return {
                tag: payload.tag,
                data: response.data.data || []
            }
        })

export const fetchUsersSearch = createAsyncThunk<TaggedDataState<any>, TaggedAction>("lines/fetchUsers",
    async (payload) => {
        const response = await api.get<ApplicationResponse<UserSearchRef[]>>(`${AppConstants.api}/users/search`)
        return {
            tag: payload.tag,
            data: response.data.data
        }
    })


export const uploadUserAssignment = createAsyncThunk<void, {
    payload: { users: string[], config: string }
}>("user/assignment",
    async ({payload}, thunkAPI) => {
        try {
            const {config} = payload
            const url = `${AppConstants.api}/devices/config/${config}/users`


            const response = await api.put<ApplicationResponse<void>>(url, payload)

            return response.data.data
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: "Network error",
                    code: "NETWORK_ERROR",
                    attributes: {}
                },
                status: "error",
                timestamp: new Date().toISOString()
            })
        }
    })

export const deleteUser = createAsyncThunk<void, {

    userId: string
    configId?: string,
},
    { rejectValue: SerializedError }>("user/deleteUser",
    async (payload, thunkAPI) => {
        try {
            const {userId, configId} = payload

            let url = `${AppConstants.api}/users/${userId}`;
            if (configId) url += `?configId=${configId}`;

            const response = await api.delete(url);

            return response.data

        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue({
                    name: "NetworkError",
                    message: "Network error",
                    stack: err.stack,
                })
                    ;
                ''
            }

            return thunkAPI.rejectWithValue({
                name: "ApiError",
                message: "Error from API",
                stack: err.stack,
            })
        }
    }
)
export const uploadUser = createAsyncThunk<void, {
    payload: NewUserModelRef
}>("user/upload",
    async ({payload}, thunkAPI) => {
        try {
            const apiCall = payload.id ? api.put : api.post
            if (payload?.id) {
                const response = await apiCall<ApplicationResponse<string>>(`${AppConstants.api}/users/${payload.id}`, payload)
            } else {
                const response = await apiCall<ApplicationResponse<string>>(`${AppConstants.api}/users`, payload)
            }
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: "Network error",
                    code: "NETWORK_ERROR",
                    attributes: {}
                },
                status: "error",
                timestamp: new Date().toISOString()
            })
        }
    })

export const uploadUserImage = createAsyncThunk<void, {
    payload: { id: string, image: File }
}>("user/uploadImage",
    async ({payload}, thunkAPI) => {
        try {
            const body = convertToForm({file: payload.image}, ["file"])
            const response = await api.put(`${AppConstants.api}/users/${payload.id}/image`, body)
            return response.data
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: "Network error",
                    code: "NETWORK_ERROR",
                    attributes: {}
                },
                status: "error",
                timestamp: new Date().toISOString()
            })
        }
    })


const usersSlice = createSecuredSlice({
    name: "users",
    initialState: {search: {}, minimal: {}} as UserStore,
    reducers: {
        clearUserList: (state, action: PayloadAction<string>) => {
            if (state.minimal[action.payload]) {
                state.minimal[action.payload] = {
                    status: "empty",
                    error: null,
                    data: state.minimal[action.payload].data
                }
            }
        },
        clearUserSearch: (state, action: PayloadAction<string>) => {
            if (state.search[action.payload]) {
                state.search[action.payload] = {
                    status: "empty",
                    error: null,
                    data: state.search[action.payload].data
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserList.pending,
            (state, action: { meta: { arg: TaggedAction } }) => {
                const {arg: {tag}} = action.meta

                state.minimal[tag] = {
                    status: "loading",
                    error: null,
                    data: []
                }
            })
            .addCase(fetchUserList.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state.minimal[tag] = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchUserList.rejected, (state,
                                              action: PayloadAction<unknown, string,
                                                  { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta
                state.minimal[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
            .addCase(fetchUsersSearch.pending,
                (state, action) => {
                    const {arg: {tag}} = action.meta

                    state.search[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchUsersSearch.fulfilled,
                (state, action) => {
                    const {
                        tag,
                        data
                    } = action.payload
                    state.search[tag] = {
                        status: "idle",
                        error: null,
                        data
                    }

                })
            .addCase(fetchUsersSearch.rejected,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.search[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: []
                    }
                })
    }
})

export const {clearUserList, clearUserSearch} = usersSlice.actions

export default usersSlice.reducer